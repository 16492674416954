* {
  box-sizing: border-box;
}

@font-face {
  font-family: abc_diatypemedium;
  src: url("abcdiatype-medium-webfont.efda95e2.woff2") format("woff2"), url("abcdiatype-medium-webfont.349c4705.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: abc_diatyperegular;
  src: url("abcdiatype-regular-webfont.8e42c98e.woff2") format("woff2"), url("abcdiatype-regular-webfont.23e6e43d.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  width: 100svw;
  height: 100svh;
  margin: 0;
  overflow: hidden;
}

::selection {
  color: #fff;
  background: #000;
}

h1, h2, h3, h4, .largeFont {
  color: #343434;
  font-family: abc_diatypemedium, sans-serif;
  font-size: 36px;
  font-weight: 100;
  line-height: 1.25;
}

@media (orientation: portrait) {
  h1, h2, h3, h4, .largeFont {
    font-size: 28px;
  }
}

p, li, a, button, .smallFont {
  color: #343434;
  font-family: abc_diatyperegular, sans-serif;
  font-size: 16px;
  line-height: 1.25;
}

@media (orientation: portrait) {
  p, li, a, button, .smallFont {
    font-size: 18px;
  }
}

button {
  background: none;
  border: none;
  text-decoration: underline;
}

button:hover {
  cursor: pointer;
}

a:hover {
  color: #ababab;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  margin: 0;
  padding: 0;
}

.container {
  width: 100vw;
  height: 100svh;
  overflow: hidden;
}

li.animateTextItem {
  text-align: center;
  pointer-events: none;
  width: 100%;
  padding: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#introduction {
  width: 100%;
  height: 100svh;
  position: absolute;
}

@media (orientation: portrait) {
  #introduction, .sending-data #introduction {
    display: none;
  }
}

#introductionPhone {
  display: none;
}

@media (orientation: portrait) {
  #introductionPhone {
    width: 100%;
    height: 100svh;
    display: block;
    position: absolute;
  }

  .sending-data #introductionPhone {
    display: none;
  }
}

.helperText {
  padding: 1rem 1rem 1rem 2rem;
}

.helperText .letter {
  opacity: 0;
}

#hashPhone {
  display: none;
}

@media (orientation: portrait) {
  .sending-data #hashPhone {
    width: 100%;
    height: 100svh;
    display: block;
    position: absolute;
    overflow: hidden;
  }
}

#hashPhone .animateTextItem {
  text-align: center;
  width: 100%;
  padding: 1rem 4rem;
  position: absolute;
  top: 1rem;
  transform: translate(-50%);
}

#orbs ul#messages {
  z-index: -1;
  width: 100svw;
  height: 100svh;
  position: absolute;
  top: 0;
  overflow: hidden;
}

#orbs ul#messages li {
  transform-origin: center;
  mix-blend-mode: soft-light;
  border-radius: 50%;
  transition: all .5s ease-in-out;
  position: absolute;
}

@media (orientation: portrait) {
  #orbs ul#messages li {
    max-width: 100vh;
    max-height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .sending-data #orbs ul#messages {
    opacity: 1;
  }

  .sending-data #orbs ul#messages li {
    filter: none;
    opacity: 0;
    background: none;
    height: 0;
    bottom: 0;
  }
}

.connectButton {
  display: none;
}

@media (orientation: portrait) {
  .connectButton {
    z-index: 999;
    background: #fff;
    border: 3px solid #343434;
    border-radius: 50%;
    min-width: 11rem;
    min-height: 11rem;
    text-decoration: none;
    transition: all .5s ease-in-out;
    display: block;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%);
  }
}

#hashPhone .connectButton {
  bottom: calc(50% - 6rem);
}

#introductionPhone .connectButton {
  opacity: 0;
}

#hashPhone .connecting.connectButton, .connecting.connectButton {
  color: #ababab;
  border: 3px solid #ababab;
  bottom: -15rem;
  transform: translate(-50%);
}

.sending-data #hashPhone .connectButton.connected, .sending-data .connectButton.connected {
  width: calc(var(--volume) / 3);
  height: calc(var(--volume) / 3);
}

#hashPhone .connectButton.connected, .connectButton.connected {
  border: 3px solid #343434;
  bottom: -15rem;
  transform: translate(-50%);
}

#qrCode {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

#qrCode img {
  border: 10px solid #fff;
  border-radius: 10%;
  width: 100px;
  height: 100px;
}

#information {
  opacity: 0;
  z-index: 99999;
  position: relative;
}

#information:focus-within {
  opacity: 1;
}

.informationButton {
  z-index: 10;
  filter: blur();
  background: #343434;
  border: none;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 0;
  transition: background 1s;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

@media (orientation: landscape) {
  .informationButton:hover {
    cursor: pointer;
    filter: blur(5.5px);
    background: #ababab;
  }
}

.informationButton span {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.showInformation .informationButton {
  background: #ababab;
}

.informationContent {
  opacity: 0;
  background: #fff;
  flex-direction: column;
  justify-content: space-between;
  width: 31.25%;
  height: 100svh;
  padding: 1rem 6.25% 1rem 2rem;
  transition: all .25s ease-in-out .5s;
  display: flex;
  position: absolute;
  top: 0;
  left: 100%;
  box-shadow: -37px 1px 81px #0000001a;
}

@media (orientation: portrait) {
  .informationContent {
    border-radius: 0 0 2.5rem 2.5rem;
    width: 100%;
    padding: 5rem 2rem 2rem;
    top: -100vh;
    left: 0;
    box-shadow: -915px 35px 256px #0000, -585px 22px 234px #00000003, -329px 13px 198px #0000000d, -146px 6px 146px #00000017, -37px 1px 81px #0000001a;
  }
}

.informationContent p {
  opacity: 0;
  transition: opacity .25s ease-in-out;
}

.showInformation .informationContent, .informationContent:focus-within {
  opacity: 1;
  left: 68.75%;
}

.showInformation .informationContent p, .informationContent:focus-within p {
  opacity: 1;
}

@media (orientation: portrait) {
  .showInformation .informationContent, .informationContent:focus-within {
    height: 85svh;
    min-height: 80svh;
    top: 0;
    left: 0;
    overflow: scroll;
  }

  .informationContent p, .informationContent a, .informationContent button {
    font-size: 14px;
  }
}

p#data {
  color: gray;
  font-size: .45rem;
  line-height: 1.25;
}

.copyUrl {
  padding: 0;
}

.extra {
  justify-content: space-between;
  display: flex;
}
/*# sourceMappingURL=index.d475338e.css.map */
