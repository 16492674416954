* {
  box-sizing: border-box;
  /*  border: 1px solid red;*/
}

@font-face {
  font-family: "abc_diatypemedium";
  src: url("fonts/abcdiatype-medium-webfont.woff2") format("woff2"),
    url("fonts/abcdiatype-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "abc_diatyperegular";
  src: url("fonts/abcdiatype-regular-webfont.woff2") format("woff2"),
    url("fonts/abcdiatype-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  height: 100vh;
  width: 100vw;
  height: 100svh;
  width: 100svw;
  overflow: hidden;
}

::selection {
  color: white;
  background: black;
}

/* Typography */

h1,
h2,
h3,
h4,
.largeFont {
  font-family: "abc_diatypemedium", sans-serif;
  font-size: 36px;
  color: #343434;
  font-weight: 100;
  line-height: 1.25;
}

@media (orientation: portrait) {
  h1,
  h2,
  h3,
  h4,
  .largeFont {
    font-size: 28px;
  }
}

p,
li,
a,
button,
.smallFont {
  font-size: 16px;
  line-height: 1.25;
  color: #343434;
  font-family: "abc_diatyperegular", sans-serif;
}

@media (orientation: portrait) {
  p,
  li,
  a,
  button,
  .smallFont {
    font-size: 18px;
  }
}

button {
  background: none;
  border: none;
  text-decoration: underline;
}

button:hover {
  cursor: pointer;
}

a:hover {
  color: #ababab;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  margin: 0;
  padding: 0;
}

.container {
  width: 100vw;
  height: 100vh;
  height: 100svh;
  overflow: hidden;
}

/* Text  */

li.animateTextItem {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 4rem;
  pointer-events: none;
}

#introduction {
  position: absolute;
  width: 100%;
  height: 100vh;
  height: 100svh;
}

@media (orientation: portrait) {
  #introduction {
    display: none;
  }
  .sending-data #introduction {
    display: none;
  }
}

#introductionPhone {
  display: none;
}

@media (orientation: portrait) {
  #introductionPhone {
    display: block;
    position: absolute;
    width: 100%;
    height: 100vh;
    height: 100svh;
  }
  .sending-data #introductionPhone {
    display: none;
  }
}

.helperText {
  padding: 1rem 1rem 1rem 2rem;
}

.helperText .letter {
  opacity: 0;
}

#hashPhone {
  display: none;
}

@media (orientation: portrait) {
  .sending-data #hashPhone {
    display: block;
    position: absolute;
    width: 100%;
    height: 100vh;
    height: 100svh;
    overflow: hidden;
  }
}

#hashPhone .animateTextItem {
  position: absolute;
  top: 1rem;
  transform: translate(-50%, 0%);
  width: 100%;
  text-align: center;
  padding: 1rem 4rem;
}

/* Orbs */

#orbs ul#messages {
  height: 100vh;
  width: 100vw;
  height: 100svh;
  width: 100svw;
  position: absolute;
  top: 0;
  overflow: hidden;
  z-index: -1;
}

#orbs ul#messages li {
  transform-origin: center;
  border-radius: 50%;
  position: absolute;
  mix-blend-mode: soft-light;
  transition: ease-in-out all 0.5s;
}

@media (orientation: portrait) {
  #orbs ul#messages li {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100vh;
    max-height: 100vh;
  }
}

@media (orientation: portrait) {
  .sending-data #orbs ul#messages {
    opacity: 1;
  }

  .sending-data #orbs ul#messages li {
    filter: none;
    background: transparent;

    height: 0;
    bottom: 0;
    opacity: 0;
  }
}

/* buttons */

.connectButton {
  display: none;
}

@media (orientation: portrait) {
  .connectButton {
    display: block;
    transition: ease-in-out all 1s 1s;
    background: white;
    border: 3px solid #343434;
    border-radius: 50%;
    min-width: 11rem;
    min-height: 11rem;
    text-decoration: none;

    z-index: 999;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0);
    transition: ease-in-out all 0.5s;
  }
}

#hashPhone .connectButton {
  bottom: calc(50% - 6rem);
}

#introductionPhone .connectButton {
  opacity: 0;
}

#hashPhone .connecting.connectButton,
.connecting.connectButton {
  border: 3px solid #ababab;
  color: #ababab;
  bottom: -15rem;
  transform: translate(-50%, 0%);
}

.sending-data #hashPhone .connectButton.connected,
.sending-data .connectButton.connected {
  width: calc(var(--volume) / 3);
  height: calc(var(--volume) / 3);
}

#hashPhone .connectButton.connected,
.connectButton.connected {
  bottom: -15rem;
  transform: translate(-50%, 0%);
  border: 3px solid #343434;
}

/* QR Code */

#qrCode {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

#qrCode img {
  width: 100px;
  height: 100px;
  border: white 10px solid;
  border-radius: 10%;
}

/* Information */

#information {
  opacity: 0;
  position: relative;
  z-index: 99999;
}

#information:focus-within {
  opacity: 1;
}

.informationButton {
  position: absolute;
  right: 2rem;
  top: 2rem;
  width: 26px;
  height: 26px;
  padding: 0;
  border-radius: 50%;
  background: #343434;
  border: none;
  z-index: 10;
  filter: blur(0);
  transition: background 1s;
}

@media (orientation: landscape) {
  .informationButton:hover {
    background: #ababab;
    cursor: pointer;
    filter: blur(5.5px);
  }
}

.informationButton span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.showInformation .informationButton {
  background: #ababab;
}

.informationContent {
  position: absolute;
  width: 31.25%;
  height: 100vh;
  height: 100svh;
  top: 0;
  left: 100%;
  transition: 0.25s all 0.5s ease-in-out;
  padding: 1rem 6.25% 1rem 2rem;

  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  box-shadow: -37px 1px 81px 0px rgba(0, 0, 0, 0.1);
}

@media (orientation: portrait) {
  .informationContent {
    left: 0;
    top: -100vh;
    width: 100%;
    padding: 5rem 2rem 2rem 2rem;

    border-radius: 0rem 0rem 2.5rem 2.5rem;
    box-shadow: -915px 35px 256px 0px rgba(0, 0, 0, 0),
      -585px 22px 234px 0px rgba(0, 0, 0, 0.01),
      -329px 13px 198px 0px rgba(0, 0, 0, 0.05),
      -146px 6px 146px 0px rgba(0, 0, 0, 0.09),
      -37px 1px 81px 0px rgba(0, 0, 0, 0.1);
  }
}

.informationContent p {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}

.showInformation .informationContent,
.informationContent:focus-within {
  left: 68.75%;
  opacity: 1;
}

.showInformation .informationContent p,
.informationContent:focus-within p {
  opacity: 1;
}

@media (orientation: portrait) {
  .showInformation .informationContent,
  .informationContent:focus-within {
    top: 0;
    left: 0;

    min-height: 80vh;
    min-height: 80svh;

    height: 80vh;
    height: 85svh;
    overflow: scroll;
  }
}

@media (orientation: portrait) {
  .informationContent p,
  .informationContent a,
  .informationContent button {
    font-size: 14px;
  }
}

p#data {
  font-size: 0.45rem;
  /*  text-transform: uppercase;*/
  line-height: 1.25;
  color: grey;
}

.copyUrl {
  padding: 0;
}

.extra {
  display: flex;
  justify-content: space-between;
}
